<template>
  <div class="toggle-wrap nk-block-tools-toggle">
    <a href="javascript:;" class="btn btn-icon btn-trigger toggle-expand mr-n1"
       :class="{active: isExpended}"
       v-on:click="toggle">
      <nio-icon icon="ni-more-v"></nio-icon>
    </a>
    <div class="toggle-expand-content" :class="{expended: isExpended}" :style="{display: isExpended ? 'block' : 'none'}">
      <ul class="nk-block-tools g-3">
        <slot></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import NioIcon from "@core/components/nio-icon/NioIcon";

export default {
  components: {NioIcon},
  setup(){

    let isExpended = ref(false)
    const toggle = () => {
      isExpended.value = !isExpended.value
    }

    return {
      isExpended,
      toggle,
    }
  },
}
</script>
